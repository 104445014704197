:root {
	--header-height: 64px;
}

body {
	font: normal 10px Montserrat, sans-serif;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.navigation :not(:last-child) {
	margin-right: 50px;
}

.button {
	transition: 0.5s;
	border-radius: 6px;
	height: 44px;
	padding: 0px 22px;
	border: none;
	text-align: center;
	text-decoration: none;
	font-size: 16px;
	font-weight: 400;
	background-color: transparent;
	box-sizing: border-box;
	border: transparent solid 1px;
}

.button span {
	cursor: pointer;
	display: inline-block;
	position: relative;
	transition: 0.5s;
}

.button span:after {
	content: "\00bb";
	position: absolute;
	opacity: 0;
	top: 0;
	right: -20px;
	transition: 0.5s;
}

.button:hover span {
	padding-right: 16px;
}

.button:hover span:after {
	opacity: 1;
	right: 0;
}

.button:hover {
	border: 1px solid rgba(0, 0, 0, 0.5);
	cursor: pointer;
}

.button:focus {
	outline: 0;
	background-color: transparent;
}

.container {
	margin-top: var(--header-height);
	text-align: center;
	display: flex;
	padding: 30px;
	background-color: #f5f7fb;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
}

.content {
	flex: 1;
	display: flex;
	max-width: 1024px;
	width: 1024px;
	flex-direction: column;
}

.header {
	height: var(--header-height);
	position: fixed;
	z-index: 9;
	top: 0;
	width: 100%;
	/* border: red solid 2px; */
	box-sizing: border-box;
	background-color: white;
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 32px;
}

.headerTitle {
	flex: 1;
	text-align: left;
	font-size: 25px;
	font-weight: "bold";
}

.img-logo-app {
	-webkit-box-shadow: -4px 13px 31px 1px rgba(0, 0, 0, 0.08);
	-moz-box-shadow: -4px 13px 31px 1px rgba(0, 0, 0, 0.08);
	box-shadow: -4px 13px 31px 1px rgba(0, 0, 0, 0.08);
}

@media (max-width: 1024px) {
	.navigation :not(:last-child) {
		margin-right: 2px;
	}
}

@media (max-width: 1040px) {
	.container {
		padding: 30px 0px;
	}
	.content {
		width: 100%;
	}
	.headerTitle {
		text-align: center;
	}
	.button {
		font-size: 13px;
	}
}

.h_iframe iframe {
	width: 100%;
	height: 100%;
}
.h_iframe {
	height: 100%;
	width: 100%;
}
